import React, { useEffect } from "react";
import { PrimaryButtonStyled } from "../../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../../OutlinedButtonStyled";
import { useGlobalModal } from "../../../../contexts/GlobalModalContext";
import { TitleFormatter, OrgFormatter } from "../../../adminRoster/AdminPersonnelTable";
import { BestTable } from "best-common-react";
import { useSelectedPersonnel } from "../../../../contexts/SelectedPersonnelContext";
import {
    FormatterProps,
    StyledIcon,
    DeleteFormatter,
    NameFormatter,
    ModalBody,
    DrugTestFormatter,
    BigsBestTable,
} from "./BulkCommon";
import { useBulkDTexemptCheck } from "../../../../customHooks/useBulkDTexemptCheck";
import { PersonnelTypes } from "../../../../constants/PersonnelTypes";

const BackgroundCheckFormatter: React.FC<FormatterProps> = ({ row }) => {
    return (
        <div className="ml-2">
            <StyledIcon color="var(--black)" iconName="fa-check" />
        </div>
    );
};

const Columns = [
    {
        key: "",
        name: "",
        width: 40,
        formatter: DeleteFormatter,
    },
    {
        key: "lastName",
        name: "Name",
        sortable: true,
        formatter: NameFormatter,
        width: 135,
    },
    {
        key: "title",
        name: "Title",
        sortable: true,
        formatter: TitleFormatter,
        width: 110,
    },
    {
        key: "org",
        name: "Club",
        sortable: true,
        formatter: OrgFormatter,
        width: 75,
    },
    {
        key: "affiliateNameLevel",
        name: "Affiliate",
        sortable: true,
        width: 210,
    },
    {
        key: "",
        name: "BGC",
        width: 55,
        formatter: BackgroundCheckFormatter,
    },
];

type BulkAcceptPersonnelModalBodyProps = {};

const BulkAcceptPersonnelModalBody: React.FC<BulkAcceptPersonnelModalBodyProps> = ({}) => {
    const {
        initialSelectedPersonnel,
        selectedPersonnel,
        setSelectedPersonnel,
    } = useSelectedPersonnel();

    useEffect(() => {
        return () => {
            setSelectedPersonnel([...initialSelectedPersonnel]);
        };
    }, []);

    useBulkDTexemptCheck(selectedPersonnel, setSelectedPersonnel);

    return (
        <ModalBody>
            <span
                style={{
                    fontFamily: "Helvetica",
                    fontStyle: "oblique",
                    color: "#92a5ba",
                    fontSize: "0.875rem",
                }}
            >
                {selectedPersonnel.length} personnel
            </span>
            <BigsBestTable>
                <BestTable
                    data={selectedPersonnel}
                    columns={Columns}
                    headerHeight={30}
                    maxTableHeight={180}
                    rowHeight={40}
                    rowExpansion={{
                        expandable: false,
                    }}
                />
            </BigsBestTable>
        </ModalBody>
    );
};

type BulkAcceptPersonnelModalFooterProps = {
    personnelAdvancedSearch(arg1: any, arg2: any, arg3: any, arg4: any): void;
    adminSelectedMenuOptions: Array<any>;
    adminAffiliationFilters: Array<any>;
    adminSearchText: Array<any> | string;
};

const BulkAcceptPersonnelModalFooter: React.FC<BulkAcceptPersonnelModalFooterProps> = ({
    personnelAdvancedSearch,
    adminSelectedMenuOptions,
    adminAffiliationFilters,
    adminSearchText,
}) => {
    const { closeModal } = useGlobalModal();
    const { selectedPersonnel, bulkAcceptPersonnel } = useSelectedPersonnel();

    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={153}
                    height={26}
                    disabled={selectedPersonnel.length === 0}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                        bulkAcceptPersonnel();
                        setTimeout(
                            () =>
                                personnelAdvancedSearch(
                                    adminSearchText && adminSearchText.length !== 0
                                        ? adminSearchText
                                        : null,
                                    adminAffiliationFilters
                                        ? adminAffiliationFilters.map(opt => opt.orgId)
                                        : null,
                                    adminSelectedMenuOptions.map(opt => opt.id),
                                    PersonnelTypes.CLUB,
                                ),
                            1000,
                        );
                    }}
                >
                    Accept Personnel
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={80}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

BulkAcceptPersonnelModalBody.displayName = "BodyContent";
BulkAcceptPersonnelModalFooter.displayName = "FooterContent";

export { BulkAcceptPersonnelModalBody, BulkAcceptPersonnelModalFooter };
